<template>
  <div>
    <h1>Test Page</h1>
    <!--<b-button @click="login">login</b-button>-->
    <b-button @click="getUserProfile">getUserProfile</b-button>
    <b-button @click="login">Login</b-button>
    <b-button @click="getUserPolicy">getUserpolicy</b-button>
    <b-button @click="getUser">getUser</b-button>
    <div>
      <pre>{{ data }}</pre>
    </div>
    <b-button @click="testAPI">testAPI</b-button>
    <b-button @click="callServer">Call Server</b-button>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  data() {
    return {
      data: null
    }
  },
  mounted() {
    ///this.callServer()
  },
  methods: {
    login() {
      window.location.href = 'https://localhost:8080/login?returnTo=http://localhost:8081/test';
    },
    getUserProfile() {
      axios.get('https://localhost:8080/api/auth/getUserProfile').then(res => {
        console.log('getUserProfile')
        console.log(res)
        this.data = res.data
      })
    },
    getUser() {
      axios.post('https://localhost:8081/api/auth/getUser', { dnvglId: "5E3A74B9-381F-4303-AC40-90F9DEF18BD5" }).then(res => {
        console.log('getUser')
        console.log(res)
        this.data = res.data
      })
    },
    getUserPolicy() {
      axios.get('http://localhost:8081/api/auth/getUserPolicy').then(res => {
        console.log('getUserPolicy')
        console.log(res)
        this.data = res.data
      })
      // let auth =
      //   'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6ImVnRTgtX1JjUGROalRfWEZUNk05Z052clNvejl2N05yNkJyNHdrYkwxbWcifQ.eyJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vYTY4NTcyZTMtNjNjZS00YmMxLWFjZGMtYjY0OTQzNTAyZTlkL3YyLjAvIiwiZXhwIjoxNjAzMTE4MjAxLCJuYmYiOjE2MDMxMTQ2MDEsImF1ZCI6IjgzMDU0ZWJmLTFkN2ItNDNmNS04MmFkLWIyYmRlODRkN2I3NSIsInVzZXJJZCI6IjVFM0E3NEI5LTM4MUYtNDMwMy1BQzQwLTkwRjlERUYxOEJENSIsImdpdmVuX25hbWUiOiJTb2hhaWIiLCJmYW1pbHlfbmFtZSI6IlRhaHNlZW4iLCJuYW1lIjoiVGFoc2VlbiwgU29oYWliIiwiZG52Z2xBY2NvdW50TmFtZSI6IlNPSFQiLCJteURudmdsR3VpZCI6IjVFM0E3NEI5LTM4MUYtNDMwMy1BQzQwLTkwRjlERUYxOEJENSIsInN1YiI6IjVlM2E3NGI5LTM4MWYtNDMwMy1hYzQwLTkwZjlkZWYxOGJkNSIsIm9pZCI6IjM4MTVmM2QzLTBmMTctNGU3MS05OGY4LTY3ZDA0ZmEwNWQ0YiIsImVtYWlsIjpbInNvaGFpYi50YWhzZWVuQGRudmdsLmNvbSJdLCJ1cG4iOiJzb2hhaWIudGFoc2VlbkBkbnZnbC5jb20iLCJtZmFUeXBlIjoibm9uZSIsIm5vbmNlIjoiYjVhOGM4NDEtMDcxMC00YTdkLThjMzUtNjg4NTc0ZmJjYzUzIiwic2NwIjoidXNlcl9pbXBlcnNvbmF0aW9uIiwiYXpwIjoiNzljNjcyNGYtZjYzYy00YTFhLTk1ZGQtOGZiMDJkMzcxN2RjIiwidmVyIjoiMS4wIiwiaWF0IjoxNjAzMTE0NjAxfQ.WAsacWrf4Dgs-zXTsNKrqT6rzFtJH_3NzOy5kbDXMv37p1BLN01T8eNoAaiyRPE6xsu0h8BSZG4520JpNrzcrPKhURAHMBDL9yATIdRWdn3MG8lt2CvQMipBmmP_ciizzYlgztIlBzxKX0kF5abTTzPZ4C5-sQGJT_JVQgKXL9NKV6FhCz8FrkKfvp7vpWpJ0eyNmDrNsP_9cM2_bKp2qynD_hCOYJAhFLvzU8zXiU7ryuTYVXRdUl4zoIRNnwXye_UkpNZlWlMBkWGbfl7w5wRA_i54hsNomXqxEAiAfI6cJaHr3pMRig0-reSax42mOohIBVnOBvq2Vn9fkxq7lw'

      // fetch('http://localhost:8080/api/policy', {
      //   method: 'GET', // *GET, POST, PUT, DELETE, etc.
      //   mode: 'cors', // no-cors, *cors, same-origin
      //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      //   credentials: 'same-origin', // include, *same-origin, omit
      //   headers: {
      //     Authorization: auth
      //   }
      // })
      //   .then(res => {
      //     return res.json()
      //   })
      //   .then(res => {
      //     console.log('getUserpolicy')
      //     console.log(res)
      //     //const responseBody = JSON.parse(res.response.body)
      //     this.data = res
      //     //if (res.statusCode == 406) {
      //     //  window.location.href = responseBody.url
      //     //}
      //   })
      //   .catch(err => {
      //     console.log(err)
      //     this.data = err.data
      //   })
    },
    // login() {
    //   axios.get('https://localhost:3000/api/auth/veracitylogin').then(() => {
    //     console.log('login')
    //   })

    //   // axios.get('https://localhost:3000/login').then(() => {
    //   //   console.log('logged in')
    //   // })
    // },

    // getuser() {
    //   axios.get('https://localhost:3000/api/auth/veracityuser').then(data => {
    //     console.log('getuser')
    //     console.log(data)
    //   })
    // }
    testAPI() {
      let auth =
        ''
      // axios
      //   .get('https://api.veracity.com/Veracity/Services/V3/my/services', {
      //     withCredentials: true,
      //     //crossdomain: true,
      //     headers: {
      //       'Ocp-Apim-Subscription-Key': '70c2b1d900744bf3970b8b29c8d4e103',
      //       Authorization: auth
      //     }
      //   })
      //   .then(() => {
      //     console.log('testAPI')
      //   })

      axios({
        method: 'get',
        url: 'https://api.veracity.com/Veracity/Services/V3/my/profile',
        withCredentials: true,
        //crossdomain: true,
        headers: {
          'Ocp-Apim-Subscription-Key': 'e945a296873b4ffbabc385b24797e348',
          Authorization: auth
        }
      }).then(function(response) {
        console.log('Header With Authentication :' + response)
      })
    },
    callServer() {
      // axios.get('https://localhost:3000/login').then(() => {
      //   console.log('logged in')
      // })
      //axios.get('https://localhost:8081/test').then(data => {
      //  console.log(data)
     // })
      axios.get('https://localhost:8081/login?returnTo=http://localhost:8080/test').then(data => {
         console.log(data)
       })

       //axios.get('https://localhost:8081/user').then(data => {
       //  console.log('resp')
       //  console.log(data)
       //})

      //http://localhost:8080/api/vessels
    }
  }
})
</script>

<style scoped></style>
