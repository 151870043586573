import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { user } from './user'
import { survey } from './survey'
import { organisation } from './organisation'
import { trend } from './trend'

Vue.use(Vuex)

export const store = new Vuex.Store({
  plugins: [
    createPersistedState()
  ],
  modules: {
    user: user,
    organisation: organisation,
    survey : survey,
    trend: trend
  }
})

// data structure
//https://docs.microsoft.com/en-us/azure/cosmos-db/modeling-data
//https://docs.mongodb.com/manual/applications/data-models/
//{
//  paths: ['user.user', 'survey.survey']
//}
