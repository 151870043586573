/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import axios from 'axios'
import config from '../config'
import router from '../routes'

const api = axios.create({
    baseURL: config.ServerEndPoint + '/api/survey'
  })


  export const survey = {
    namespaced : true,
    state : {
      organisationAuthentication : false,
      userSurveyInfo: {},
      userSurveyList: {},
      authenticatedOrganisation : {},
      surveyPageData : {},
      userSurveyInput : {}
    },
    getters : {
      getOrganisationAuthentication: state => {
        return state.organisationAuthentication
      },
      getAuthenticatedOrganisation : state =>{
        return state.authenticatedOrganisation
      },
      getUserSurveyInfo: state => {
        return state.userSurveyInfo
      },
      getUserSurveyList: state => {
        return state.userSurveyList
      },
      getSurveyPageData: state => {
        return state.surveyPageData
      },
      getUserSurveyInput: state => {
        return state.userSurveyInput
      }
    },
    actions : {
      async checkOrganisationAuthentication({ commit }, payload) {

        return await api
                .post('/organisationAccesscode', { OrganisationAccessCode: payload.organisationAccessCode })
                .then(res => {
                  //commit('organisationAuthentication', true)
                  console.log(res.data)
                  commit('setAuthenticatedOrganisation', res.data)
                  commit('setOrganisationAuthentication', true)
                })
      },

      async checkUserSurveyInfo({ commit }, payload) {
        await api
                .post('/getUserSurveyInfo', { UserID: payload.UserID })
                .then(res => {
                  //commit('organisationAuthentication', true)
                  console.log(res.data)
                  commit('setUserSurveyInfo', res.data)
                })
      },
      
      async getUserSurveyList({ commit }, payload) {
        await api
                .post('/getUserSurveyList', { UserID: payload.UserID })
                .then(res => {
                  //commit('organisationAuthentication', true)
                  console.log(res.data)
                  commit('setUserSurveyList', res.data)
                })
      },
      

      async updateUserSurveyInfo({ commit }, payload) {
        await api
                .post('/addUserSurveyInfo', {
                      UserAgeInfo: payload.UserAgeInfo,
                      UserServiceInfo: payload.UserServiceInfo,
                      SurveyStatus : payload.SurveyStatus,
                      UserID: payload.UserID })
                .then(res => {
                  //commit('organisationAuthentication', true)
                  //console.log(res.data)
                  commit('setUserSurveyInfo', res.data)
                })
      },

      async loadSurveyPage ({commit}, payload){
        return await api
                      .post('/getSurveyPageData', {sectionID : payload.sectionID})
                      .then(res =>{
                        //console.log(res.data)
                        commit('setSurveyPageData',res.data)
                      })
      },

      async loadUserSurveyInput ({commit}, payload){
        return await api
                      .post('/getUserSurveyInput', {SurveyID :  payload.UserSurveyListID})
                      .then(res =>{
                        //console.log(res.data)
                        commit('setUserSurveyInput',res.data)
                      })
      },

      async saveUserSurveyInput ({commit}, payload){
        return await api
                      .post('/saveUserSurveyInput', {surveyPageAnswers : payload.surveyPageAnswers, UserSurveyListID : payload.UserSurveyListID})
                      .then(res =>{
                        //console.log(res.data)
                        //commit('setUserSurveyInput',res.data)
                      })
      }
    },
    mutations : {
      setOrganisationAuthentication(state, payload) {
        state.organisationAuthentication = payload
      },
      setAuthenticatedOrganisation (state, payload){
        state.authenticatedOrganisation = payload
      },
      setUserSurveyInfo(state, payload) {
        state.userSurveyInfo = payload
      },
      setUserSurveyList(state, payload) {
        state.userSurveyList = payload
      },
      setSurveyPageData(state, payload) {
        state.surveyPageData = payload
      },
      setUserSurveyInput(state, payload) {
        state.userSurveyInput = payload
      }
    }
  }