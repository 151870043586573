<template>
    <div class="dashboard">
        <Sidebar/>
        <div class="content overflow-auto">
            <router-view />
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Sidebar from '../components/SideBar'

export default Vue.extend({
  name: 'dashboard',
  components : {
      Sidebar
  }
})
</script>

<style>
.dashboard {
    display: grid;
    grid-template-columns: 1fr 5fr;
    background-color: #E5E5E5;
    height: 50vh;
    width: 100vw;
}
.content {
    background-color: white;
    border-radius: 10px;
    margin: 6px 6px 6px 0px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: lightgray; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgrey; 
}
</style>