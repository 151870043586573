/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import axios from 'axios'
import config from '../config'
import router from '../routes'

const api = axios.create({
    baseURL: config.ServerEndPoint + '/api/organisation'
  })


  export const organisation = {
    namespaced : true,
    state : {
      userOrganisation: {}
    },
    getters : {
      getUserOrganisation: state => {
        return state.userOrganisation
      }
    },
    actions : {
      async getUserOrganisation({ commit }, payload) {
        return api
          .post('/getUserOrganisation', { OrganisationRef: payload.OrganisationRef })
          .then(res => {
            console.log('MyISRS User Organistion:')
            console.log(res.data)
            commit('setUserOrganisation', res.data)
          })
          .catch(err => {
            console.log(err)
            if (err && err.response && err.response.status == 401) {
              router.push('/')
            }
          })
      },

      async updateUserDepartment({ commit }, payload) {
        return api
          .post('/updateUserDepartment', { UserID :  payload.UserID, OrganisationRef: payload.OrganisationRef })
          .then(res => {
            console.log('MyISRS Updated User Organistion:')
            console.log(res.data)
            commit('setUserOrganisation', res.data)
          })
          .catch(err => {
            console.log(err)
            if (err && err.response && err.response.status == 401) {
              router.push('/')
            }
          })
      },

      async addNewDepartment({ commit }, payload) {
        return api
          .post('/addNewOrganisationDetails', { 
                DepartmentCode :  payload.DepartmentCode, 
                DepartmentName: payload.DepartmentName,
                Location : payload.Location,
                Address : payload.Address,
                OrganisationID : payload.OrganisationID
          })
          .then(res => {
            console.log('MyISRS added a new department under the Organistion:')
            console.log(res.data)
            //commit('setUserOrganisation', res.data)
          })
          .catch(err => {
            console.log(err)
            if (err && err.response && err.response.status == 401) {
              router.push('/')
            }
          })
      }
    },
    mutations : {
      setUserOrganisation(state, payload) {
        state.userOrganisation = payload
      }
    }
  }
