import Vue from 'vue'
//import axios from 'axios'
import App from './App.vue'
import router from './routes.js'
import BootstrapVue from 'bootstrap-vue'
import OneDesignVue from '@dnvgl-onefoundation/onedesign-vue'
//import { sync } from 'vue-router-sync'
import { store } from './store/index'
import ChartKick from 'vue-chartkick'
import {Chart} from 'chart.js'


Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(OneDesignVue)
Vue.use(ChartKick.use(Chart))

//sync(store,router)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

