<template>
    <OInfoPage :hide-icon="hideIcon" fa-icon="fa-cogs">
        <h1 class="mt-0">No Subscription Found !</h1>
        <p>Please contact your local super user / admin for your access code. You can view your subscribed services <a href="https://services.veracity.com">here</a>.</p>
    </OInfoPage>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      hideIcon: false
    }
  }
})
</script>

<style scoped></style>