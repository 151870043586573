/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '../src/store/index'
import axios from 'axios'
import moment from 'moment'

import Test from './views/Test.vue'
import NotFound from './views/NotFound.vue'
import MissingSubscription from './views/MissingSubscription.vue'
import Unauthorized from './views/Unauthorized.vue'
import Dashboard from './views/Dashboard.vue'
import SurveyStart from './views/SurveyStart.vue'
import SurveyNotice from './views/SurveyNotice.vue'
import SurveyAddInfo from './views/SurveyAddInfo.vue'
import SurveyFinish from './views/SurveyFinish.vue'
import Survey from './views/Survey.vue'

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [
      { path: '/404', component: NotFound },
      { path: '/406', name : 'missingsubscription', component: MissingSubscription, hideMenu: true},
      { path: '*', redirect: '/404' },
      {
        path: '/unauthorized',
        name: 'unauthorized',
        component: Unauthorized,
        hideMenu : true
      },
      {
        path: '/loginreturn',
        name: 'loginreturn',
        async beforeEnter(to, from, next) {
          try {
            console.log('Enter loginreturn')
            store
              .dispatch('user/getUser')
              .then(() => {
                console.log(store.getters['user/getUser'].username)
                next('/dashboard')
              })
          } catch (e) {
            console.log('ROUTER NO PERMISSION')
            console.log(e)
            next('/unauthorized')
          }
        }
      },
      {
        path: '/',
        name: 'landing',
        async beforeEnter(to, from, next) {
          try {
            console.log('Enter landing')
            //@ts-ignore
            const account = myMSALObj.getAccount()
            if (!account || moment.unix(account.idToken.exp).isBefore(moment())) {
              console.log('Enter signIn Redirect page')
              store.dispatch('user/signIn')
              return
            } else {
              console.log('Enter request Token')
              store
                .dispatch('user/requestToken')
                .then(accessToken => {
                  return store
                          .dispatch('user/getUserPolicy', {
                            token : accessToken
                          })
                          .then(() => {
                            if (store.getters['user/getUserPolicy']) {
                              console.log(store.getters['user/getUserPolicy'])
                              const responseBody = JSON.parse(
                                store.getters['user/getUserPolicy'].response.body
                              )
                              console.log(responseBody.message)
                              if (
                                store.getters['user/getUserPolicy'].statusCode == 406
                              ) {
                                console.log(responseBody.subCode)
                                if(responseBody.subCode == 0)
                                {
                                  next('/406')
                                }
                                else{
                                  window.location.href = responseBody.url
                                }
                              } else if (
                                store.getters['user/getUserPolicy'].statusCode == 204
                              ) {
                                next()
                              } else {
                                console.log(
                                  store.getters['user/getUserPolicy'].statusCode
                                )
                              }
                            } else {
                              console.log('Fetching the User from the database.')
                              //console.log(accessToken)
                              //console.log(account)
                              return store
                                      .dispatch('user/getUser', {
                                        dnvAccount: account.idToken,
                                        token : accessToken
                                      })
                                      .then(() => {
                                        next('/dashboard')
                                      })
                                      .catch(e => {
                                        console.log(e)
                                        next('/unauthorized')
                                      })
                            }
                          })
                })
                .catch(e => {
                  console.log(e)
                  next('/unauthorized')
                })
            }
          } catch (e) {
            console.log('ROUTER NO PERMISSION')
            console.log(e)
            next('/unauthorized')
          }
        }
      },
      {
        path: '/dashboard',
        component: Dashboard,
        children: [
          {
            path: '/dashboard',
            component: () => import(/* webpackChunkName: "Overview" */ './views/Overview.vue')
          },
          {
            path: '/trends',
            component: () => import(/* webpackChunkName: "Messages" */ './views/Trends.vue')
          },
          {
            path: '/Admin',
            component: () => import(/* webpackChunkName: "Profile" */ './views/Admin.vue')
          }
        ],
        async beforeEnter(to, from, next) {
          try {
            if (!axios.defaults.headers.common['Authorization']) {
              console.log("it is reset to landing page")
              next('/')
            }
            console.log('Enter Home page')
            var hasPermission = store.getters['user/getUser'].UserRoleRef ===  1 || 4
            if (hasPermission) {
              console.log('ROUTER HAS PERMISSION')
              next()
            } else {
              throw 'user does not have permission'
            }
          } catch (e) {
            console.log('ROUTER NO PERMISSION')
            console.log(e)
            next('/unauthorized')
          }
        } 
      },
      {
        path: '/surveyStart',
        component: SurveyStart,
        async beforeEnter(to, from, next) {
          try {
            //if (!axios.defaults.headers.common['Authorization']) {
            //  console.log("it is reset to landing page")
            //  next('/')
            //}
            console.log('Enter Survey Start page')
            next()
          } catch (e) {
            console.log('ROUTER NO PERMISSION')
            console.log(e)
            next('/unauthorized')
          }
        }
      },
      {
        path: '/surveyNotice',
        component: SurveyNotice,
        async beforeEnter(to, from, next) {
          try {
            console.log(store.getters['survey/getOrganisationAuthentication'])
            if(store.getters['survey/getOrganisationAuthentication'])
            {
              console.log('Enter Survey Notice Page')
              next()
            }
            else{
              console.log('Organisation Access code is not validated')
              next('/dashboard')
            }
          } catch (e) {
            console.log('ROUTER NO PERMISSION')
            console.log(e)
            next('/unauthorized')
          }
        }
      },
      {
        path: '/surveyAddInfo',
        component: SurveyAddInfo,
        async beforeEnter(to, from, next) {
          try {
            console.log(store.getters['survey/getOrganisationAuthentication'])
            if(store.getters['survey/getOrganisationAuthentication'])
            {
              console.log('Enter Survey Additional Info Page')
              next()
            }
            else{
              console.log('Organisation Access code is not validated')
              next('/dashboard')
            }
          } catch (e) {
            console.log('ROUTER NO PERMISSION')
            console.log(e)
            next('/unauthorized')
          }
        }
      },
      {
        path: '/survey',
        component: Survey,
        async beforeEnter(to, from, next) {
          try {
            if(store.getters['survey/getOrganisationAuthentication'])
            {
              console.log('Enter Survey page')
              next()
            }
            else{
              console.log('Organisation Access code is not validated')
              next('/dashboard')
            }
            //if (!axios.defaults.headers.common['Authorization']) {
            //  console.log("it is reset to landing page")
            //  next('/')
            //}
          } catch (e) {
            console.log('ROUTER NO PERMISSION')
            console.log(e)
            next('/dashboard')
          }
        }
      },
      {
        path: '/surveyFinish',
        component: SurveyFinish,
        async beforeEnter(to, from, next) {
          try {
            //if (!axios.defaults.headers.common['Authorization']) {
            //  console.log("it is reset to landing page")
            //  next('/')
            //}
            console.log('Enter Survey Finish page')
            next()
          } catch (e) {
            console.log('ROUTER NO PERMISSION')
            console.log(e)
            next('/unauthorized')
          }
        }
      },
      {
        path: '/test',
        name: 'Test',
        component: Test
      }
    ]
})