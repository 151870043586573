/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import axios from 'axios'
import config from '../config'
import router from '../routes'

const api = axios.create({
    baseURL: config.ServerEndPoint + '/api/trend'
  })


  export const trend = {
    namespaced : true,
    state : {
      isrsProcessChartData : [],
      orgSurveyProgressData : [],
      isrsProcessTableData : []
    },
    getters : {
      getIsrsProcessChartData: state => {
        return state.isrsProcessChartData
      },
      getOrgSurveyProgress: state => {
        return state.orgSurveyProgressData
      },
      getIsrsProcessTableData: state => {
        return state.isrsProcessTableData
      }
    },
    actions : {
      async fetchIsrsProcessChartData({ commit }, payload) {

        return await api
                .post('/isrsProcessScore', { OrganisationID: payload.OrganisationId })
                .then(res => {
                  //commit('organisationAuthentication', true)
                  console.log(res.data)
                  commit('setIsrsProcessChartData', res.data)
                })
      },

      async fetchOrgSurveyProgress({ commit }, payload) {

        return await api
                .post('/orgSurveyProgress', { OrganisationID: payload.OrganisationId })
                .then(res => {
                  //commit('organisationAuthentication', true)
                  console.log(res.data)
                  commit('setOrgSurveyProgress', res.data)
                })
      },

      async fetchIsrsProcessTableData({ commit }, payload) {

        return await api
                .post('/isrsProcessTable', { OrganisationID: payload.OrganisationId })
                .then(res => {
                  //commit('organisationAuthentication', true)
                  console.log(res.data)
                  commit('setIsrsProcessTableData', res.data)
                })
      }
    },
    mutations : {
      setIsrsProcessChartData(state, payload) {
        state.isrsProcessChartData = payload
      },
      setOrgSurveyProgress(state,payload){
        state.orgSurveyProgressData = payload
      },
      setIsrsProcessTableData(state, payload){
        state.isrsProcessTableData = payload
      }
    }
  }