<template>
  <div class="info-page" style="height: 500px">
    <div class="info-page-block block bg-white block-shadow">
      <div class="d-flex flex-column flex-sm-row align-items-center">
        <div class="info-page-icon">
          <i class="fal fa-exclamation fa-5x"></i>
        </div>
        <div class="info-page-content">
          <h1 class="avenir-bold info-page-title">
            Unauthorized - You do not have access to this page
          </h1>
          <p>
            <strong
              >Please <a href="/">sign in</a> or contact the help desk for
              access.</strong
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  data() {
    return {}
  },
  computed: {
    ...mapGetters('user', ['getUser'])
  },
  mounted() {},
  methods: {
    ...mapActions('user', ['signIn'])
  }
})
</script>

<style scoped></style>
