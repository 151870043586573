<template>
  <O404 :hide-icon="hideIcon" />
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      hideIcon: false
    }
  }
})
</script>

<style scoped></style>
