<template>
  <div class="container-fluid">
     <div class="mt-4 col-md-3 text-left">
      <h3>Survey Notice</h3>
      <hr class="mt-n2">
    </div>
    <div class="col-md-10 shadow p-3 mb-5 bg-white rounded mt-5 m-auto">
      <div class="row bg-cyan-light ml-1 mr-1 justify-content-center">
          <div class="p-2 pl-3">
            <span class="text-white text-bold">Notice before this survey</span>
          </div>
      </div>
      <div class="row ml-1 mr-1 bg-sky-blue-light">
        <div class="col-2 p-2 pl-3 my-auto">
          <img src="../assets/notification_blue3.png" alt="" srcset="" width="70" height="70">
        </div>
        <div class="col-10 text-left p-2 pl-3 my-auto">
          <p><span class="avenir-demi text-small text-left">The purpose of the assessment is to get a high level understanding of the organisation's management system performance. This will be measured against the management processes defined in DNV's International Sustainability Rating System (ISRS).</span></p>
          <p><span class="avenir-demi text-small text-left">ISRS is tried and tested management system framework that consists of 15 business processes describing the best practice management across the processes.</span></p>
          <ul class="avenir-demi text-small text-left">
            <li class="mt-2">The assessment contains 166 questions derived from the latest ISRS 9th Edition protocol.</li>
            <li class="mt-2">The survey responses shared with your organisation will be completely anonymous.</li>
            <li class="mt-2">DNV Guarantees individual respondents confidentiality of information.</li>
            <li class="mt-2">Please choose an answer from “Strongly Agree” to “Strongly Disagree” for each question.</li>
            <li class="mt-2">If you do not know the answer or if the question is not applicable to you, please select N/A.</li>
            <li class="mt-2">All questions are mandatory.</li>
            <li class="mt-2">Estimated time to complete this assessment is approximately 40-50 mins.</li>
            <li class="mt-2">If you cannot do the assessment in one sitting, you can pause it and start it later from where you left off.</li>
            <li class="mt-2">It is best to complete the assessment on a computer. </li>
          </ul>
          <p><span class="avenir-demi text-small text-left">For more information on ISRS, visit <a href="http://www.dnv.com/ISRS" target="_blank" rel="noopener noreferrer">www.dnv.com/ISRS</a> or <a href="http://www.isrs.net" target="_blank" rel="noopener noreferrer">www.isrs.net </a></span></p>
        </div>
      </div>
      <div class="row ml-1 mr-1 justify-content-center">
         <div class="col-2 p-3">
           <b-button :variant="variant" :disabled="disabled" :class="size" @click="gotoSurveyaddInfo">
            {{ text }}
            <i class="fal ml-4" :class="iconClass"></i>
          </b-button>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'


export default Vue.extend({
  name : 'surveynotice',
  data () {
    return {
      variant: "primary",
      disabled: false,
      iconClass: "fas fa-arrow-alt-right",
      size: "btn-lg",
      text: "Continue"
    }
  },
  methods : {
      gotoSurveyaddInfo () {
          this.$router.push('/surveyAddInfo')
      }
  }
})
</script>

<style scoped>

</style>
