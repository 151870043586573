<template>
  <div class="container-fluid">
     <div class="mt-4 col-md-2 text-left">
      <h1>Survey</h1>
      <hr class="mt-n2">
    </div>
    <div class="col-md-10 shadow p-3 mb-5 bg-white rounded mt-5 m-auto">
      <div class="row bg-cyan-light ml-1 mr-1">
          <div class="text-left p-2 pl-3">
            <h2 class="text-white">{{getSurveyPageData.title}}</h2>
          </div>
      </div>
      <div class="row ml-1 mr-1 bg-sky-blue">
        <div class="text-left p-2 pl-3">
          <h2> {{getSurveyPageData.subtitleCode}}   {{ getSurveyPageData.subtitle }}</h2>
        </div>
      </div>
      <div class="row ml-1 mr-1 p-3 avenir-medium" style="background-color:#EBF7FC">
        <div class="col-md-6 float-left text-left">
          <span class="text-small">.</span>
        </div>
        <div class="col-md-1 float-left text-center">
          <span class="text-small">Strongly Agree</span>
        </div>
        <div class="col-md-1 float-left text-center">
          <span class="text-small">Agree</span>
        </div>
        <div class="col-md-1 float-left text-center">
          <span class="text-small">Neutral</span>
        </div>
        <div class="col-md-1 float-left text-center">
          <span class="text-small">Disagree</span>
        </div>
        <div class="col-md-1 float-left text-center">
          <span class="text-small">Strongly Disagree</span>
        </div>
        <div class="col-md-1 float-left text-center">
          <span class="text-small">N/A</span>
        </div>
      </div>
      <div 
        v-for="(question, index) in getSurveyPageData.questions"
        :key="question.ID"
        :class="{ 'bg-sky-blue-light': index % 2 === 0, 'bg-sky-blue-lightest' : index % 2 !== 0 }"
        class="row ml-1 mr-1 p-3"
      >
          <div class="col-md-6 float-left text-left">
            <span class="avenir-demi">{{ question.Q_TEXT_FULL}}</span>
          </div>
           <div class="col-md-1 float-left text-center">
              <input type="radio" :name="question.UniqueCode" value="5" v-model="answers[index]">
          </div>
          <div class="col-md-1 float-left text-center">
              <input type="radio" :name="question.UniqueCode" value="4" v-model="answers[index]">
          </div>
          <div class="col-md-1 float-left text-center">
              <input type="radio" :name="question.UniqueCode" value="3" v-model="answers[index]">
          </div>
          <div class="col-md-1 float-left text-center">
              <input type="radio" :name="question.UniqueCode" value="2" v-model="answers[index]">
          </div>
          <div class="col-md-1 float-left text-center">
              <input type="radio" :name="question.UniqueCode" value="1" v-model="answers[index]">
          </div>
          <div class="col-md-1 float-left text-center">
              <input type="radio" :name="question.UniqueCode" value="6" v-model="answers[index]">
          </div>
      </div>
       <div class="row ml-1 mr-1">
         <div class="col-12 p-3">
            <hr class="end"/>
         </div>
      </div>
      <div class="row ml-1 mr-1 justify-content-center">
         <div class="col-10 p-3">
            <b-progress 
              :value="value" 
              :max="max" 
              :show-progress="showProgress" 
              :show-value="showValue" 
            />
            <OBadge :variant="badgevariant" :outline="outline">
              {{ badgetext }}
            </OBadge>
         </div>
      </div>
      <div class="row ml-1 mr-1 justify-content-end" v-bind:class="{ 'justify-content-between': getSurveyPageData.previousSectionId }">
         <div v-if="getSurveyPageData.previousSectionId" class="col-3 p-3">
           <b-button :variant="buttonvariant" :class="size" @click="previousSection">
            <i class="fal mr-4" :class="previousiconClass"></i>
            {{ previousbuttontext }}
          </b-button>
         </div>
         <div class="col-3 p-3">
           <b-button :variant="buttonvariant" :class="size" @click="saveExit">
            <i class="fal mr-2 fa-save"></i>
            Save &amp; Exit
            <i class="fal ml-2 fa-sign-out-alt"></i>
          </b-button>
         </div>
         <div class="col-3 p-3" v-if="getSurveyPageData.nextSectionId">
           <b-button :variant="buttonvariant"  :class="size" @click="nextSection">
            {{ nextbuttontext }}
            <i class="fal ml-4" :class="nexticonClass"></i>
          </b-button>
         </div>
         <div class="col-3 p-3" v-if="!getSurveyPageData.nextSectionId">
           <b-button :variant="buttonvariant"  :class="size" @click="finishSurvey">
            Submit Survey
            <i class="fal ml-4" :class="finishiconClass"></i>
          </b-button>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState, mapActions} from 'vuex'


export default Vue.extend({
  name : 'survey',
  data () {
    return {
      value: 0,
      max: 190,
      showProgress: false,
      showValue: false,
      badgevariant: "secondary",
      badgetext: "0%",
      outline: false,
      buttonvariant: "primary",
      size: "btn-md",
      nextbuttontext: "Next",
      previousbuttontext: "Previous",
      disabled: false,
      nexticonClass: "fas fa-arrow-alt-right",
      previousiconClass: "fas fa-arrow-alt-left",
      finishiconClass : "fas fa-clipboard-check",
      pageValidation : false,
      answers : []
    }
  },
  async mounted () {

    //load the survey from the last completed question
    console.log(this.getUserSurveyInput.length)
    if(this.getUserSurveyInput.length>0){

      await this.loadUserSurveyInput({
        UserSurveyListID : this.getUserSurveyInfo.ID
      })

      var last = Object.keys(this.getUserSurveyInput).pop();

      var qUniCode = this.getUserSurveyInput[last].Q_CODE
      var res = qUniCode.split("_");
      var qcode =""
      // if(qUniCode.length == 7 && last < 131)
      // {
      //   qcode = res[1].substr(0, 1) + "." +  res[1].substr(1, 1) + "." + res[1].substr(2, 2)
      // }
      // else if(qUniCode.length == 7 && last > 130)
      // {
      //   qcode = res[1].substr(0, 2) + "." +  res[1].substr(2, 1) + "." + res[1].substr(3, 1)
      // }
      // else {
      //   qcode = res[1].substr(0, 1) + "." +  res[1].substr(1, 1) + "." + res[1].substr(2, 1)
      // }
      qcode = res[1] + "." + res[2] + "." + res[3];

      var lastValue = res.pop(); 
      var firstValue = res.join("_");
      console.log(lastValue+"-"+firstValue)
      
      await this.loadSurveyPage({sectionID : qcode })
      this.value = this.getSurveyPageData.questionCount
      const percentageValue =  ((this.value / 190) * 100).toFixed()
      this.badgetext = percentageValue.toString() + "%"

      var result
      if(this.getSurveyPageData.questions.length == 1)
      {
        result = this.getUserSurveyInput.filter(el => el.Q_CODE == qUniCode)
      }
      else {
        result = this.getUserSurveyInput.filter(el => el.Q_CODE.includes(firstValue))
      }
      result.forEach(element => {
         this.answers.push(element.Q_CODE_Answer)
      })
      //await this.loadSurveyPage({sectionID : '1.1.1'})
      //console.log(this.getUserSurveyInput)
    }
    else {
      
      await this.loadSurveyPage({sectionID : '1.1.1'})
      this.value = this.getSurveyPageData.questionCount
      this.badgetext = this.getSurveyPageData.questionCount.toString() + "%"
      
    }
    
  },
  computed :{
     ...mapState('survey',['organisationAuthentication']),
     ...mapState('user',['user']),
     ...mapGetters('survey', ['getSurveyPageData','getUserSurveyInput','getUserSurveyInfo'])
  },
  methods : {
    ...mapActions('survey', ['checkOrganisationAuthentication','loadSurveyPage','saveUserSurveyInput','loadUserSurveyInput','updateUserSurveyInfo']),
    async nextSection() {
      try {
          this.saveAnswer()
          //alert("answer is Saved:" + this.pageValidation)
          if(this.pageValidation)
          {
            //alert("load a new page")
            await this.loadSurveyPage({sectionID : this.getSurveyPageData.nextSectionId})
            await this.loadUserSurveyInput({
                UserSurveyListID : this.getUserSurveyInfo.ID
              })
            var qUCode = []
            this.getSurveyPageData.questions.forEach(question => {
                qUCode.push(question.UniqueCode)
            })

            qUCode.forEach(code => {
              var r = this.getUserSurveyInput.filter(el => el.Q_CODE == code)
              r.forEach(element => {
                this.answers.push(element.Q_CODE_Answer)
              })
            })

            this.value = this.getSurveyPageData.questionCount
            const percentageValue =  ((this.value / 190) * 100).toFixed()
            this.badgetext = percentageValue.toString() + "%"
          }     
      }
      catch(e){
        console.log(e)
      }
    },
    async previousSection() {
      try {

        this.saveAnswer()
          if(this.pageValidation)
          {
            await this.loadSurveyPage({sectionID : this.getSurveyPageData.previousSectionId})
            await this.loadUserSurveyInput({
                UserSurveyListID : this.getUserSurveyInfo.ID
            })

            var qUCode = []
            this.getSurveyPageData.questions.forEach(question => {
                qUCode.push(question.UniqueCode)
            })

            qUCode.forEach(code => {
              var r = this.getUserSurveyInput.filter(el => el.Q_CODE == code)
              r.forEach(element => {
                this.answers.push(element.Q_CODE_Answer)
              })
            })

            this.value = this.getSurveyPageData.questionCount
            const percentageValue = ((this.value / 190) * 100).toFixed();
            this.badgetext = percentageValue.toString() + "%"
          }
      }
      catch(e){
        console.log(e)
      }
    },
    async saveAnswer() {
      try {

          if(this.answers.length == this.getSurveyPageData.questions.length)
          {
            this.pageValidation=true
            //alert("Answer saving started")
            var pageAnswers = {}
            this.getSurveyPageData.questions.forEach((value, index) => {
              pageAnswers[value.UniqueCode] = this.answers[index]
            });
            await this.saveUserSurveyInput({
              surveyPageAnswers : pageAnswers,
              UserSurveyListID : this.getUserSurveyInfo.ID
            })
            this.answers = []
            //alert("Answer saving finished : "+ this.pageValidation)
          }
          else{
            this.pageValidation=false
            //alert(this.answers.length + " -" + this.getSurveyPageData.questions.length)
            alert("Please provide answers for all the questions.")
          }
       
      }
      catch(e){
        console.log(e)
      }
    },

    async finishSurvey(){
      try{
        this.saveAnswer()
        if(this.pageValidation)
        {
            const surveyStatus  = "Completed"
            await this.updateUserSurveyInfo({
              SurveyStatus : surveyStatus,
              UserID: this.user.ID
            })
            this.$router.push('/surveyFinish')
        }
      }
      catch(e) {
        console.log(e)
      }
    },

    async saveExit(){
      try{
        this.saveAnswer()
        if(this.pageValidation)
        {
          this.$router.push('/dashboard')
        }

      }
      catch(e){
        console.log(e)
      }
    }
  }

})
</script>

<style scoped>
.end{
   border: 1px solid skyblue;
}
.badge {
  margin-top:5px;
  font-size: smaller;
}
</style>
