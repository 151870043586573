<template>
  <div class="container-fluid">
     <div class="mt-4 col-md-3 text-left">
      <h3>MyISRS Survey</h3>
      <hr class="mt-n2">
    </div>
    <div class="col-md-10 shadow p-3 mb-5 bg-white rounded mt-5 m-auto">
      <div class="row bg-cyan-light ml-1 mr-1 justify-content-center">
          <div class="p-2 pl-3">
            <span class="text-white text-bold">Authentication for ISRS online survey</span>
          </div>
      </div>
      <div class="row ml-1 mr-1 bg-sky-blue-light">
        <div class="col-2 p-2 pl-3">
          <img src="../assets/security2.png" alt="" srcset="" width="70" height="70">
        </div>
        <div class="col-10 text-left p-2 pl-3 my-auto">
          <span class="text-bold text-small">Please do your authentication for ISRS online survey by entering the organisational access code what you received via e-mail.Make sure to respond to the questions by yourself.</span>
        </div>
      </div>
      <div class="row ml-1 mr-1 bg-sky-blue-lightest">
        <div v-if="alert" class="col-12 text-left pt-5">
          <OAlert 
            :variant="variant" 
            :message="message" 
            :description="description"
            :dismissable="dismissable" 
            @dismiss="closeAlert"
          />
        </div>
      </div>
      <div class="row ml-1 mr-1 bg-sky-blue-lightest">
        <div class="float-left pt-3 col-12 text-left mb-5">
          <OForm
              v-model="details"
              :fields="fields"
              :inline="inline"
              :ok-button-text="okButtonText"
              @ok="onAuthenticate"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions} from 'vuex'

export default Vue.extend({
  name : 'surveystart',
  data () {
    return {
      variant: "danger",
      message: "",
      description: "",
      dismissable: false,
      alert:false,
      inline: false,
      fields: [
        {
          name: 'OrganisationAccessCode',
          heading: 'Access Code',
          type: 'input',
          invalidFeedback: 'Please enter the access code',
          required: true
        },
        {
          name: "Consent",
          heading: "Consent to Collection and Use of Personal Information",
          type: "checkbox",
          value: true,
          invalidFeedback: "Please provide your consent before the survey begins.",
          required: true
        }
      ],
      okButtonText: 'Authenticate',
      details: {
        OrganisationAccessCode :"",
        Consent : false
      }
    }
  },
  mounted () {

    //Remove the unwanted cancel button from form.  Does not seem to be an option to do this in the component
    const cancelButton = document.getElementById('btnCancel')
    if (cancelButton) {
      cancelButton.style.display = 'none'
    }
  },
  computed :{
     ...mapState('survey',['organisationAuthentication'])
  },
  methods : {
    ...mapActions('survey', ['checkOrganisationAuthentication']),
    async onAuthenticate () {
      try{
        if(this.details.Consent)
        { 
            const orgCode =  this.details.OrganisationAccessCode
            await this.checkOrganisationAuthentication({organisationAccessCode : orgCode})
            if(this.organisationAuthentication){
              this.$router.push('/surveyNotice')
            } 
        }
        else{
          this.alert = true
          this.message = "Consent!!"
          this.description = "Please provide your consent before the survey begins."
        }
      }
      catch(e){
        console.log(e)
        this.alert = true
        this.message = "Access Code Invalid"
        this.description = "Please contact DNV Admin for correct ogranisation access code."
      }
       
    },
    closeAlert(){
      this.alert = false;
    }
  }
  
})
</script>

<style scoped></style>
