/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import axios from 'axios'
import config from '../config'
import * as Msal from 'msal'
import router from '../routes'

const api = axios.create({
    baseURL: config.ServerEndPoint + '/api/auth'
  })

var appConfig = {
  b2cScopes: [
    'https://dnvglb2cprod.onmicrosoft.com/83054ebf-1d7b-43f5-82ad-b2bde84d7b75/user_impersonation'
  ]
}

const tenantId = 'dnvglb2cprod.onmicrosoft.com'
const policyName = 'B2C_1A_SignInWithADFSIdp'
const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_CLIENT_ID,
    authority:  `https://login.veracity.com/tfp/${tenantId}/${policyName}/v2.0/.well-known/openid-configuration`,
    validateAuthority: false,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    postLogoutRedirectUri: 'https://www.veracity.com/auth/logout'
  },
  cache: {
    cacheLocation: 'localStorage'
  }
}

// instantiate MSAL
window.myMSALObj = new Msal.UserAgentApplication(msalConfig)

// request to signin - returns an idToken
const loginRequest = {
  scopes: appConfig.b2cScopes
}

// request to acquire a token for resource access
const tokenRequest = {
  scopes: appConfig.b2cScopes
}


//acquire a token silently
function getToken(tokenRequest) {
  return myMSALObj.acquireTokenSilent(tokenRequest).catch(function(error) {
    console.log('aquire token popup')
    // fallback to interaction when silent call fails
    return myMSALObj
      .acquireTokenPopup(tokenRequest)
      .then(function(tokenResponse) {
        return tokenResponse
      })
      .catch(function(error) {
        logMessage('Failed token acquisition', error)
      })
  })
}

// updates the UI post login/token acqusition
function updateUI() {
  const userName = myMSALObj.getAccount().name
  console.log(myMSALObj.getAccount())
}

// calls the resource API with the token
function callApi() {
  getToken(tokenRequest).then(function(tokenResponse) {
    callApiWithAccessToken(tokenResponse.accessToken)
  })
}

// helper function to access the resource with the token
function callApiWithAccessToken(accessToken) {
}

export const user = {
  namespaced : true,
  state : {
    userVeracity : {},
    user: {},
    isInit: false,
    userPolicy: {}
  },
  getters : {
    getUser: state => {
      return state.user
    },
    getUserVeracity: state => {
      return state.userVeracity
    },
    getIsInit: state => {
      return state.isInit
    },
    getUserPolicy: state => {
      return state.userPolicy
    }
  },
  actions : {
    async initUser({ commit }) {
      //use the bearer token to access the veracity user profile
      //This will get the ID we need
      await api
        .get('/getUserProfile')
        .then(res => {
          console.log('Veractiy User Info:')
          console.log(res)
          res.data.company.name = 'DNV' //TEMP
          commit('setUserVeracity', res.data)

          return res.data
        })
        .then(user => {
          //Then use this ID to look up the user on the myTVAS DB
          return api
            .post('/login', { id: user.id, operator: user.company.name })
            .then(res => {
              //append the myTVAS info to the veracity user object
              user.imgSrc = res.data.imgSrc
              user.token = res.data.token
              user.role = res.data.role

              console.log('MyISRS User Info:')
              console.log(user)

              commit('setUser', user)
            })
        })
    },
    async getUser({ commit }, payload) {
      return api
        .post('/getUser', { dnvAccount: payload.dnvAccount })
        .then(res => {
          console.log('MyISRS User Info:')
          console.log(res.data)
          commit('updateUser', res.data)
        })
        .catch(err => {
          console.log(err)
          if (err && err.response && err.response.status == 401) {
            router.push('/')
          }
        })
    },
    async getUserPolicy({ commit }, payload) {
      return api
        .get('/getUserPolicy')
        .then(res => {
          console.log('UserPolicy')
          if (res.data) {
            console.log('Error:' + res.data)
          }
          console.log('UserPolicyCheck - Finished.')
          commit('setUserPolicy', res.data)
        })
        .catch(err => {
          console.log(err)
          if (err && err.response && err.response.status == 401) {
            router.push('/')
          }
          // const responseBody = JSON.parse(res.response.body)
          // if (err.statusCode == 406) {
          //   window.location.href = responseBody.url
          // }
        })
    },
    async login({ commit }, payload) {
      //get token here
      await api.post('/login', { email: payload.email }).then(res => {
        commit('setUser', res.data)
        // commit('setisInit', true)
        console.log('user set')
        console.log(res.data)
      })
    },
    async resetUser({ commit }) {
      console.log('user reset')
      // Removes all sessions, need to call AAD endpoint to do full logout
      commit('setUser', {})
      myMSALObj.logout()
      myMSALObj.clearCache()
    },
    async signIn({ commit }, payload) {
      function authCallback(error, response) {
        //handle redirect response
      }

      myMSALObj.handleRedirectCallback(authCallback)
      myMSALObj.loginRedirect(loginRequest)
      commit('setisInit', true)
    },
    async requestToken({ commit }, payload) {
      return getToken(tokenRequest).then(tokenResponse => {
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + tokenResponse.accessToken
        api.defaults.headers.common['Authorization'] =
          'Bearer ' + tokenResponse.accessToken
        commit('updateUser', { token: tokenResponse.accessToken })
        return tokenResponse.accessToken
      })
    },
    doUpdateUser({ commit }, payload) {
      commit('updateUser', payload)
    }
  },
  mutations : {
    setUserVeracity(state, payload) {
      state.userVeracity = payload
    },
    setUser(state, payload) {
      state.user = payload
    },
    setUserPolicy(state, payload) {
      state.userPolicy = payload
    },
    setisInit(state, payload) {
      state.isInit = payload
    },
    updateUser(state, payload) {
      state.user = Object.assign(state.user || {}, payload)
    }
  }
}