<template>
  <div id="app">
    <OHeader
      :siteinfo="siteinfo"
      :compact="compact"
      :container="container"
      :hide-menu="hideMenu"
    >
        <b-nav-item-dropdown
        v-if="getUser"
        class="o-header-dropdown-user"    
        menu-class="o-header-dropdown-menu"
        id="user-dropdown"
        right
        no-caret
        ref="userdropdown"
        >
        <template slot="button-content">
          <img
            v-if="getUserImgSrc"
            style="width: 30px; border-radius: 15px;"
            :src="getUserImgSrc"
          />
          <i v-else style="width: 30px;" class="fal fa-user-alt icon-padding">
          </i>
        </template>
        <b-dropdown-header class="o-header-dropdown-menu-item">Your profile</b-dropdown-header>
        <div class="o-header-dropdown-menu-item">
          <span class="text-bold" id="loginView_loginName">{{ getUser.FirstNames }} {{getUser.LastNames}}</span>
        </div>
        <b-dropdown-divider class="o-header-dropdown-divider"></b-dropdown-divider>
        <b-dropdown-header class="o-header-dropdown-menu-item">Manage account</b-dropdown-header>
        <div id="loginStatusPanel">
          <span id="loginViewSettings_loginName">
            <b-dropdown-item 
              class="o-header-dropdown-menu-item"
              @click="goToSettings"
            >
              Settings
            </b-dropdown-item>
          </span>
          <b-dropdown-item
            id="BRIXLoginStatus"
            class="o-header-dropdown-menu-item" 
            @click="onClickLogout"
          >
            Log out
          </b-dropdown-item>
        </div>
  
      </b-nav-item-dropdown>
      <li v-if="!getUser" class="o-header-action">
        <a class="o-header-action-btn" id="login-button" href="login">
          Login
        </a>        
      </li>
    </OHeader>
    <router-view style="min-height:85vh; width:95vw;" class="ml-5 mb-1" />
    <OFooter />
  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters, mapActions} from 'vuex'

export default Vue.extend({
  name: 'App',
  data(){
    return{
        "siteinfo": {
          "title": "MyISRS"
        },
        "userLogged": true,
        "hideIfLoggedOff": true,
        "container": true,
        "compact": true,
        "hideMenu": true,
        "disableHamburger": false
    }
  },
  computed : {
    ...mapGetters('user', ['getUser']),
    getUserImgSrc() {
      if (!this.getUser.imgSrc) {
        return null
      } else {
        return `./images/avatars/avatar-dnv.jpg`
      }
    }
  },
  methods:{
    ...mapActions('user', ['resetUser']),
    goToSettings() {
      location.href = 'https://services.veracity.com/EditProfile'
    },
    onClickLogout() {
      this.resetUser()
    }
  }
})
</script>

<style lang="scss">
$onedesign-root-path: "~@dnvgl-onefoundation/onedesign/src/";
@import "~@dnvgl-onefoundation/onedesign/src/scss/imports.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
