<template>
  <div class="container-fluid">
     <div class="mt-4 col-md-3 text-left">
      <h3>Survey User Info</h3>
      <hr class="mt-n2">
    </div>
    <div class="col-md-10 shadow p-3 mb-5 bg-white rounded mt-5 m-auto">
       <div class="row bg-cyan-light ml-1 mr-1 justify-content-center">
          <div class="p-2 pl-3">
            <span class="text-white text-bold">Additional Information</span>
          </div>
       </div>
       <div class="row ml-1 mr-1 bg-sky-blue-light">
        <div class="col-2 p-2 pl-3 my-auto">
          <img src="../assets/notification_blue3.png" alt="" srcset="" width="70" height="70">
        </div>
        <div class="col-10 text-left p-2 pl-3 my-auto">
          <span class="text-bold text-small">Please answer the following questions to understand your work experience and demographics.</span>
        </div>
       </div>
       <div class="row ml-1 mr-1 bg-sky-blue-lightest">
        <div class="float-left pt-3 col-12 text-left mb-5">
          <OForm
                v-model="details"
                :fields="fields"
                :inline="inline"
                :ok-button-text="okButtonText"
                @ok="onSubmit"
              />
        </div>
       </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions} from 'vuex'


export default Vue.extend({
  name : 'surveyaddinfo',
  data () {
    return {
      inline: false,
      fields: [
        {
            name: "ServiceYears",
            heading: "Experience in the Organisation",
            type: "select",
            required: true,
            options: [
            {
                value: "1",
                text: "Less than 1 year"
            },
            {
                value: "5",
                text: "1 - 5 years"
            },
            {
                value: "10",
                text: "6 - 10 years"
            },
            {
                value: "15",
                text: "11 - 15 years"
            },
            {
                value: "20",
                text: "16 - 20 years"
            },
            {
                value: "25",
                text: "21 - 25 years"
            },
            {
                value: "30",
                text: "26 - 30 years"
            },
            {
                value: "31",
                text: "More than 31 years"
            }]
        },
        {
            name: "AgeGroup",
            heading: "Age Group",
            type: "select",
            required: true,
            options: [
            {
                value: "25",
                text: "Under 25 years"
            },
            {
                value: "30",
                text: "26 - 30 years"
            },
            {
                value: "40",
                text: "31 - 40 years"
            },
            {
                value: "50",
                text: "41 - 50 years"
            },
            {
                value: "55",
                text: "51 - 55 years"
            },
            {
                value: "56",
                text: "56 years or older"
            }]
        }
      ],
      okButtonText: 'Continue',
      details: {
        ServiceYears :"",
        AgeGroup: ""
      }
    }
  },
  async mounted () {

    //Remove the unwanted cancel button from form.  Does not seem to be an option to do this in the component
    const cancelButton = document.getElementById('btnCancel')
    if (cancelButton) {
      cancelButton.style.display = 'none'
    }

    await this.checkUserSurveyInfo({ UserID : this.user.ID})
    if(this.userSurveyInfo)
    {
      this.details.ServiceYears = this.userSurveyInfo.UserServiceInfo
      this.details.AgeGroup = this.userSurveyInfo.UserAgeInfo
    }


  },
  computed :{
     ...mapState('survey',['userSurveyInfo']),
     ...mapState('user',['user'])
  },
  methods : {
    ...mapActions('survey', ['checkUserSurveyInfo', 'updateUserSurveyInfo']),
    async onSubmit () {
      try{
        const serviceLength = this.details.ServiceYears
        const ageGroup  = this.details.AgeGroup
        const surveyStatus  = "In progress"
        this.updateUserSurveyInfo({
          UserAgeInfo: ageGroup,
          UserServiceInfo: serviceLength,
          SurveyStatus : surveyStatus,
          UserID: this.user.ID
        })
        .then(res => {
            console.log(res)
            this.$router.push('/survey')
        })
      }
      catch(e){
        console.log(e)
      }
       
    }
  }
})
</script>

<style scoped></style>
