<template>
    <div class="sidebar">
      <div class="menu-items">
        <router-link to="/dashboard" active-class="active" tag="button" exact class="side-btn">
          <div class="link-container avenir-regular">
            Account Overview
          </div>
        </router-link>
        <router-link v-if="getUser.UserRoleRef == 1 || getUser.UserRoleRef == 2" to="/trends" active-class="active" tag="button" exact class="side-btn">
          <div class="link-container avenir-regular">
            Survey Trends
          </div>
        </router-link>
        <router-link v-if="getUser.UserRoleRef == 1" to="/admin" active-class="active" tag="button" exact class="side-btn">
          <div class="link-container avenir-regular">
            Admin Tools
          </div>
        </router-link>
      </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters} from 'vuex'

export default Vue.extend({
  name: 'sidebar',
  computed :{
     ...mapGetters('user',['getUser'])
  },
})
</script>

<style scoped>
.sidebar {
  position:relative;
  max-width:none;
}

.menu-items {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 6px;
}
.menu-items > * {
    margin-top: 60px;
}
.side-btn {
    border: none;
    padding: 16px 0px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: black  ;
    background-color: transparent;
}
.side-btn:focus {
    outline: none;
}
.side-btn.active {
    position: relative;
    background-color: white;
    color: #003591;
    font-weight: 600;
    margin-left: 10px;
    border-radius: 30px 0 0 30px;
}
.side-btn.active::before {
    top: -30px;
}
.side-btn.active::after {
    bottom: -30px;
}
.side-btn.active::before, .side-btn.active::after {
    position: absolute;
    content: "";
    right: 0;
    height: 30px;
    width: 30px;
    background-color: white;
}
.side-btn.active .link-container::before {
    top: -60px;
}
.side-btn.active .link-container::after {
    bottom: -60px;
    z-index: 99;
}
.side-btn.active .link-container::before, .side-btn.active .link-container::after {
    position: absolute;
    content: "";
    right: 0px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color:#E5E5E5;
}
</style>