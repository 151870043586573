<template>
  <div class="container-fluid">
     <div class="mt-4 col-md-3 text-left">
      <h3>Survey Completed</h3>
      <hr class="mt-n2">
    </div>
    <div class="col-md-10 shadow p-3 mb-5 bg-white rounded mt-5 m-auto">
      <div class="row bg-cyan-light ml-1 mr-1 justify-content-center">
          <div class="p-2 pl-3">
            <span class="text-white text-bold">You have completed the survey</span>
          </div>
      </div>
      <div class="row ml-1 mr-1 bg-sky-blue-light">
        <div class="col-2 p-2 pl-3 my-auto">
          <img src="../assets/smile.png" alt="" srcset="" width="70" height="70">
        </div>
        <div class="col-10 text-left p-2 pl-3 my-auto">
           <p><span class="avenir-demi text-small text-left">Thanks for taking your time to complete this assessment. The results of this assessment will be used to identify improvement opportunities and best practices within the organisation’s management system.</span></p>
           <p><span class="avenir-demi text-small text-left">For more information on ISRS, visit <a href="http://www.dnv.com/ISRS" target="_blank" rel="noopener noreferrer">www.dnv.com/ISRS</a> or <a href="http://www.isrs.net" target="_blank" rel="noopener noreferrer">www.isrs.net </a></span></p>
        </div>
      </div>
      <div class="row ml-2 mr-2">
          <div class="col-12 my-auto p-5">
              <img src="../assets/thankyou4.png" alt="" srcset="">
          </div>
      </div>
      <div class="row ml-1 mr-1 justify-content-center">
         <div class="col-4 p-3">
           <b-button :variant="variant" :disabled="disabled" :class="size" @click="gotoHome">
            {{ text }}
            <i class="fal ml-4" :class="iconClass"></i>
          </b-button>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'


export default Vue.extend({
  name : 'surveynotice',
  data () {
    return {
      variant: "primary",
      disabled: false,
      iconClass: "fas fa-home",
      size: "btn-lg",
      text: "Back to Home"
    }
  },
  methods : {
      gotoHome () {
          this.$router.push('/dashboard')
      }
  }
})
</script>

<style scoped>

</style>
